import {useParams, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";

function TermsAndConditionPage(props) {
    return (<section className="grid items-center bg-gray-100 h-full max-w-screen-sm">
            <div className="flex flex-col items-center justify-center">
                <h2 className="m-4 text-lg font-semibold text-gray-900 text-center">Terms & Conditions</h2>
            </div>
            <div className="m-2 p-4 h-max rounded-lg bg-white shadow-lg">
                <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Customer offer:</h2>
                <ul className="max-w-md space-y-1 text-black list-disc list-inside">
                    <li>
                        Customer is defined as the person who is submitting and sharing a review of the restaurant, for
                        the
                        customer’s dine-in experience during their visit to the restaurant.
                    </li>
                    <li>
                        Customer offer is the offer provided by the restaurant to the customer.
                    </li>
                    <li>
                        Customer offer is only applicable once and once per table, irrespective of the number of
                        customers
                        present on the table who would have submitted or shared the review.
                    </li>
                    <li>
                        To avail the customer offer, the customer must – a) submit a review of the restaurant for their
                        dine-in experience, b) display the submitted review to the restaurant staff and c) display
                        WhatsApp
                        app showing review shared with at least three of customers’ WhatsApp contacts to the restaurant
                        staff.
                    </li>
                </ul>
            </div>
            <div className="m-2 p-4 h-max rounded-lg bg-white shadow-lg">
                <h2 className="mb-2 text-lg font-semibold text-gray-900">Contact offer:</h2>
                <ul className="max-w-md space-y-1 text-black list-disc list-inside">
                    <li>
                        Contact refers to the WhatsApp contact with whom the review was shared and who has entered their
                        name and phone number to check the customer review.
                    </li>
                    <li>
                        Contact offer is the offer provided by the restaurant to the contact.
                    </li>
                    <li>
                        Contact offer is only applicable once and once per table, irrespective of the number of
                        contacts/customers present on the table.
                    </li>
                    <li>
                        To avail the contact offer, contact must – a) display the contact offer code to the restaurant
                        staff for redemption and b) use the contact offer code on or before the code validity date.
                    </li>
                    <li>
                        Contact offer is only applicable for restaurant dine-in and not for orders placed via Zomato and
                        Swiggy.
                    </li>
                </ul>
            </div>
            <div className="m-2 p-4 h-max rounded-lg bg-white shadow-lg">
                <ul className="max-w-md space-y-1 text-black list-disc list-inside">
                    <li>
                        Restaurant reserves the right to modify, suspend, or cancel any of these terms and conditions at
                        its sole discretion.
                    </li>
                    <li>
                        Only one offer either customer offer or contact offer, can be applied to a restaurant table
                        bill.
                    </li>
                </ul>
            </div>
        </section>
    );
}

export default TermsAndConditionPage;