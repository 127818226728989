import React, {useState} from 'react'
import {OrbitSpinner} from "react-epic-spinners";
import {Button, Input, Typography} from "@material-tailwind/react";
import useFetchData from "../util/Network";
import {useNavigate} from "react-router-dom";
import SimpleNavbar from "../common/SimpleNavbar";
import {ArrowUpIcon} from "@heroicons/react/24/solid";
import {BASE_URL} from "../Constants";
import instance from "../util/NetworkInstance";
import {optional} from "../util/util";

const AddRestaurantPage = () => {
    const navigate = useNavigate();
    const {data, error, isLoading, fetchData} = useFetchData();
    const [restaurantName, setRestaurantName] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [zomatoLink, setZomatoLink] = useState('');
    const [swiggyLink, setSwiggyLink] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const [image, setRestaurantImage] = useState(null);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [imageUploadMessage, setImageUploadMessage] = useState('');

    async function uploadRestaurantImage() {
        if (!image) return;
        const formData = new FormData();
        formData.append('file', image);
        try {
            setIsImageUploading(true)
            const response = await instance.post(BASE_URL + '/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                console.log('upload response:', response);
                setImageUploadMessage('Image uploaded successfully!');
                setImageUrl(response.data.data.url);
                setIsImageUploading(false)
            }
        } catch (error) {
            setIsImageUploading(false)
            console.error('Upload error:', error);
            setImageUploadMessage('Upload failed.');
        }
    }

    const handlePasteEvent = (event) => {
        event.preventDefault();
        const pastedData = event.clipboardData.getData('text/plain');
        if (pastedData && pastedData.includes(',')) {
            const latLong = pastedData.split(",");
            console.log(latLong);
            if(latLong.length > 1){
                setLatitude("");
                setLatitude(latLong[0].trim());
                setLongitude(latLong[1].trim());
            }
        }
    };


    async function handleAddRestaurant() {
        try {
            const postData = {
                name: restaurantName,
                city: city,
                address: address,
                overallRatings: 0,
                latitude: optional(latitude),
                longitude: optional(longitude),
                zomatoLink: optional(zomatoLink),
                swiggyLink: optional(swiggyLink),
            }
            if (imageUrl) {
                postData['imageUrl'] = imageUrl;
            }
            console.log(postData);
            const header = {"Authorization": "Bearer " + localStorage.getItem("token")};
            const resp = await instance.post("/restaurants",
                postData,
                {headers: header}
            )
            const result = await resp?.data;
            if (result) {
                navigate("/restaurants/detail/" + result.data.id);
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className="h-screen">
            <SimpleNavbar/>
            <section className="grid text-center items-center m-10">
                {isLoading ? (<OrbitSpinner color="black" className="m-auto"/>) :
                    (<div>
                            <Typography className="mb-16 text-gray-600 font-normal text-[16px]">
                                Enter Restaurant details
                            </Typography>
                            <div className="mx-auto max-w-[24rem] text-left">
                                <div className="mb-6">
                                    <Input
                                        variant="outlined"
                                        id="name"
                                        color="gray"
                                        size="lg"
                                        type="text"
                                        name="name"
                                        required={true}
                                        label="Restaurant Name"
                                        value={restaurantName}
                                        onChange={(e) => setRestaurantName(e.target.value)}
                                        className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                    />
                                </div>
                                <div className="mb-6">
                                    <Input
                                        size="lg"
                                        label="City"
                                        required={true}
                                        className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        type="text"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </div>
                                <div className="mb-6">
                                    <Input
                                        size="lg"
                                        label="Address"
                                        required={true}
                                        className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        type="text"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </div>
                                <div className="flex mb-6 gap-2 justify-evenly ">
                                    <Input
                                        onPaste={handlePasteEvent}
                                        containerProps={{className:"!min-w-[0px]"}}
                                        size="lg"
                                        label="Latitude"
                                        className="placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        type="text"
                                        value={latitude}
                                        onChange={(e) => setLatitude(e.target.value)}
                                    />
                                    <Input
                                        onPaste={handlePasteEvent}
                                        containerProps={{className:"!min-w-[0px]"}}
                                        size="lg"
                                        label="Longitude"
                                        className="placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        type="text"
                                        value={longitude}
                                        onChange={(e) => setLongitude(e.target.value)}
                                    />
                                </div>
                                <div className="mb-6">
                                    <Input
                                        size="lg"
                                        label="Zomato Link"
                                        className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        type="text"
                                        value={zomatoLink}
                                        onChange={(e) => setZomatoLink(e.target.value)}
                                    />
                                </div>
                                <div className="mb-6">
                                    <Input
                                        size="lg"
                                        label="Swiggy Link"
                                        className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        type="text"
                                        value={swiggyLink}
                                        onChange={(e) => setSwiggyLink(e.target.value)}
                                    />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="image">
                                        <Typography
                                            variant="small"
                                            className="block font-medium text-gray-900">
                                            Restaurant Picture
                                        </Typography>
                                    </label>
                                    <Input
                                        variant="standard"
                                        id="image"
                                        color="gray"
                                        size="lg"
                                        type="file"
                                        name="image"
                                        placeholder="Restaurant Picture"
                                        icon={<i onClick={uploadRestaurantImage} className="rounded bg-gray-200 p-1">
                                            <ArrowUpIcon className="h-5 w-5"/>
                                        </i>}
                                        onChange={(e) => setRestaurantImage(e.target.files[0])}
                                        className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                    />

                                    {isImageUploading ? (<OrbitSpinner color="black" className="m-auto"/>) : (
                                        <Typography className="mb-2 mt-2 text-gray-600 font-normal text-[16px]">
                                            {imageUploadMessage}
                                        </Typography>
                                    )}
                                </div>
                                {error && <Typography className="mb-16 mt-16 text-red-500 font-normal text-[16px]">
                                    {error.message}
                                </Typography>
                                }

                                <Button color="gray" size="lg" className="mt-6" fullWidth onClick={handleAddRestaurant}>
                                    Add Restaurant
                                </Button>

                            </div>
                        </div>
                    )}
            </section>
        </div>
    );

}

export default AddRestaurantPage 