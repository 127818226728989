import {Button} from "@material-tailwind/react";
import React from "react";
import { useNavigate } from 'react-router-dom';

function HomePage(props) {
    const navigate = useNavigate();
    function handleLogin(){
        const token = localStorage.getItem('token');
        if (token){
            navigate("/dashboard");
        }else{
            navigate("/login");
        }
    }
    return (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                <img src="brand-logo.png" alt="brand-image" width="256px" className="m-auto"/>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Welcome to Restaurants Review</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">Please login to see the dashboard.</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Button color="gray" className="w-full px-4 md:w-[8rem]" onClick={handleLogin}>
                        Sign In
                    </Button>
                    <a href="whatsapp://send?text=Contact Support!" data-action="share/whatsapp/share"
                       className="text-sm font-semibold text-gray-900">
                        Contact support <span aria-hidden="true">&rarr;</span></a>
                </div>
            </div>
        </main>
    )

}

export default HomePage;