import {useParams} from "react-router-dom";
import useFetchData from "../util/Network";
import React, {useEffect, useState} from "react";
import {OrbitSpinner} from "react-epic-spinners";
import {CheckBadgeIcon, TicketIcon,ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {Button, CardBody, Input, Rating, Typography} from "@material-tailwind/react";
import instance from "../util/NetworkInstance";

function VerifyCouponPage(props) {
    const {id} = useParams();
    const {data, error, isLoading, fetchData} = useFetchData();
    const [code, setCode] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isFailed, setIsFailed] = useState(false);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    useEffect(() => {
        fetchData("/restaurants/" + id)
    }, [id]);

    async function handleVerifyCoupon() {
        try {
            const header = {"Authorization": "Bearer " + localStorage.getItem("token")};
            const postData = {
                code: code,
                restaurantId: id
            }
            const resp = await instance.post("/verifyCoupon", postData, {headers: header});
            const data = resp?.data;
            console.log(resp);
            if (data) {
                setCode("");
                setSuccessMessage(data.message);
                setIsFailed(resp.status === 202);
            }
        } catch (e) {
            setErrorMessage(e.message);
            console.error(e);
        }
    }
    useEffect(() => { //validation
        setButtonEnabled(code.length === 8);
    }, [code]);

    function handleFocusChange(e){
        setSuccessMessage("");
        setErrorMessage("");
    }
    return (
        <section className="grid bg-gray-100 max-w-screen-sm h-screen">
            {(isLoading) ? (<OrbitSpinner color="black" className="m-auto"/>) :
                ((data) && <div className=''>
                        <div className='flex m-2 p-2 overflow-y-auto rounded-lg bg-white shadow-lg'>
                            <div><img src={data.data.imageUrl ? data.data.imageUrl : "/brand-logo.png"} alt="logo"
                                      className='w-24 rounded'/></div>
                            <div className='ml-2'>
                                <h1 className='font-bold text-xl ml-2 mr-2'>{data.data.name}</h1>
                                <div className="flex ml-2 mr-2 items-center">
                                    <Rating value={parseInt(data.data.overallRatings)} unratedColor="gray"
                                            ratedColor="blue"
                                            readonly/>
                                    {<Typography color="gray" className="ml-2 font-bold text-[14px]">
                                        {data.data.reviews > 1 ? data.data.reviews + " guests" : data.data.reviews + " guest"}
                                    </Typography>}
                                </div>

                            </div>
                        </div>

                        <div className="mt-16 m-2 p-4 rounded-lg bg-white shadow-lg">
                            <div className="flex"><TicketIcon className="w-6 h-6"/>
                                <Typography color="blue-gray" className="font-bold text-[18px] ml-2">
                                Enter Coupon Code
                            </Typography></div>
                            <div className="sm:flex justify-evenly mt-6">
                            <div className="sm:mt-0 mt-4 sm:w-56 w-full">
                                <Input
                                    variant="outlined"
                                    size="lg"
                                    type="text"
                                    maxLength="8"
                                    containerProps={{className: "!min-w-[0px]"}}
                                    label="Coupon Code"
                                    value={code}
                                    onFocus={(e)=>handleFocusChange(e)}
                                    onChange={(e) => setCode(e.target.value)}
                                    className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                />
                            </div>
                            <div className="sm:mt-0 mt-4 sm:w-56 w-full">
                                <Button color="gray" onClick={handleVerifyCoupon} disabled={!buttonEnabled}>
                                    Verify Coupon Code
                                </Button>
                            </div>
                            </div>
                        </div>
                       <div className="space-x-4 mt-4 mb-4">
                           {errorMessage && <Typography className="font-bold text-[16px] text-red-500">{errorMessage}</Typography>}
                           {successMessage && <div className="flex ml-4 mr-4">
                               <i className={isFailed ? "text-red-500" : "text-green-500"}>
                                   {isFailed ? (<ExclamationTriangleIcon className="w-6 h-6"/>) : (
                                       <CheckBadgeIcon className="w-6 h-6"/>)}
                               </i>
                               <Typography color={isFailed ? "red" : "green"} className="font-bold text-[16px] ml-2">
                                   {successMessage}
                               </Typography>
                           </div>}
                       </div>
                    </div>
                )}
        </section>
    );

}

export default VerifyCouponPage;