import axios from "axios";
import {BASE_URL, BASE_URL_CLIENT} from "../Constants";

const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        // Add CORS headers if needed
        //'Access-Control-Allow-Origin': BASE_URL_CLIENT,
        //'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE',
        //'Access-Control-Allow-Headers': 'Authorization, Origin, X-Requested-With, Content-Type, Accept',
    }
});

export default instance;