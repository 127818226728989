import React, {useEffect, useState} from 'react'
import {OrbitSpinner} from "react-epic-spinners";
import {
    Button,
    Card,
    CardBody,
    Dialog,
    DialogBody, DialogFooter,
    DialogHeader, IconButton,
    Input,
    Typography
} from "@material-tailwind/react";
import useFetchData from "../util/Network";
import {useNavigate, useParams} from "react-router-dom";
import SimpleNavbar from "../common/SimpleNavbar";
import {ArrowUpIcon, TrashIcon} from "@heroicons/react/24/solid";
import instance from "../util/NetworkInstance";
import {optional} from "../util/util";

const TABLE_HEAD = [
    {
        head: "Customer Offer",
        customStyle: "!text-left",
    },
    {
        head: "Lead Offer",
        customStyle: "text-right",
    },
    {
        head: "From Date",
        customStyle: "text-right",
    },
    {
        head: "To Date",
        customStyle: "text-right",
    },
    {
        head: "Validity Days",
        customStyle: "text-right",
    },
    {
        head: "Number Of Leads Coupon",
        customStyle: "text-right",
    },
    {
        head: "Actions",
        customStyle: "text-right",
    },
];
const AddOfferPage = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {data, error, isLoading, fetchData} = useFetchData();
    const [customerOffer, setCustomerOffer] = useState('');
    const [leadOffer, setLeadOffer] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [validityDays, setValidityDays] = useState(0);
    const [numberOfLeadsCoupon, setNumberOfLeadsCoupon] = useState(3);
    const [errorMessage, setErrorMessage] = useState(null);
    const [offers, setOffers] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
        fetchData("/restaurants/" + id);
        // fetchData1("/restaurants/"+id)
        fetchOffers()
    }, []);

    async function fetchOffers() {
        const header = {"Authorization": "Bearer " + localStorage.getItem("token")};
        const result = await instance.get("/offer/restaurant/" + id, {headers: header});
        if (result) {
            const data = await result?.data;
            console.log(data.data);
            setOffers(data.data)
        }
    }

    async function handleAddOffer() {
        try {
            const header = {"Authorization": "Bearer " + localStorage.getItem("token")};
            const postData = {
                customerOffer: optional(customerOffer),
                leadOffer: optional(leadOffer),
                fromDate: new Date(fromDate).getTime(),
                toDate: new Date(toDate).getTime(),
                validityDays:validityDays,
                numberOfLeadsCoupon:numberOfLeadsCoupon,
                restaurantId: id
            }
            const resp = await instance.post("/offer", postData, {headers: header});
            const data = await resp?.data;
            console.log(data);
            console.log(postData);
            if (data){
                //reset fields
                setCustomerOffer("");
                setLeadOffer("");
                setFromDate("");
                setToDate("");
                setValidityDays(0);
                setNumberOfLeadsCoupon(3);
                await fetchOffers();
            }
        } catch (e) {
            setErrorMessage(e.message);
            console.error(e);
        }
    }
    const handleOpenDeleteDialog = () => setOpenDeleteDialog(!openDeleteDialog);

    async function deleteRestaurant(id) {
        try {
            const header = {"Authorization": "Bearer " + localStorage.getItem("token")};
            const result = await instance.delete( "/offer/" + id, {headers: header});
            console.log(result);
            handleOpenDeleteDialog();
            await fetchOffers();
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className="h-screen">
            <SimpleNavbar/>
            <section className="m-2 sm:m-10 ">
                {isLoading ? (<OrbitSpinner color="black" className="m-auto"/>) :
                    (data && <Card className="items-center text-center mt-2">
                            <Typography variant="h5" color="blue-gray" className="mb-2 bold font-normal">
                                Add {data.data.name} Offers
                            </Typography>
                            <CardBody className="flex flex-wrap space-x-4">
                                <div className=" ml-4 sm:mt-0 mt-3 sm:w-52 w-full">
                                    <Input
                                        variant="outlined"
                                        size="lg"
                                        type="text"
                                        containerProps={{className:"!min-w-[0px]"}}
                                        label="Customer Offers"
                                        value={customerOffer}
                                        onChange={(e) => setCustomerOffer(e.target.value)}
                                        className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                    />
                                </div>
                                <div className="sm:mt-0 mt-3 sm:w-52 w-full">
                                    <Input
                                        size="lg"
                                        label="Lead Offer"
                                        containerProps={{className:"!min-w-[0px]"}}
                                        className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        type="text"
                                        value={leadOffer}
                                        onChange={(e) => setLeadOffer(e.target.value)}
                                    />
                                </div>
                                <div className="sm:mt-0 mt-3 sm:w-48 w-full">
                                    <Input
                                        size="lg"
                                        label="From"
                                        required={true}
                                        className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        type="datetime-local"
                                        value={fromDate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                    />
                                </div>
                                <div className="sm:mt-0 mt-3 sm:w-48 w-full">
                                    <Input
                                        size="lg"
                                        label="To"
                                        required={true}
                                        className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        type="datetime-local"
                                        value={toDate}
                                        onChange={(e) => setToDate(e.target.value)}
                                    />
                                </div>
                                <div className="sm:mt-0 mt-3 sm:w-24 w-full">
                                    <Input
                                        size="lg"
                                        label="Validity Days"
                                        containerProps={{className:"!min-w-[0px]"}}
                                        required={true}
                                        className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        type="number"
                                        value={validityDays}
                                        onChange={(e) => setValidityDays(e.target.value)}
                                    />
                                </div>
                                <div className="sm:mt-0 mt-3 sm:w-36 w-full">
                                    <Input
                                        size="lg"
                                        containerProps={{className:"!min-w-[0px]"}}
                                        label="Number of Leads Coupon"
                                        required={true}
                                        className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                        type="number"
                                        value={numberOfLeadsCoupon}
                                        onChange={(e) => setNumberOfLeadsCoupon(e.target.value)}
                                    />
                                </div>
                                <div className="sm:mt-0 mt-3 sm:w-56 w-full">
                                    <Button color="gray" className="ml-2" onClick={handleAddOffer}>
                                        Add Offer
                                    </Button>
                                </div>
                            </CardBody>
                            {errorMessage && <Typography className="mb-4 mt-2 text-red-500 font-normal text-[16px]">
                                {errorMessage}
                            </Typography>
                            }
                            <div className="overflow-scroll !px-0 py-0 mt-6 w-full">
                                <table className="w-full min-w-max table-auto text-left ">
                                    <thead>
                                    <tr>
                                        {TABLE_HEAD.map(({head, customStyle}) => (
                                            <th key={head}
                                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-bold text-light-blue-700 text-lg leading-none opacity-70">
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {offers.map(({
                                                     id,
                                                     customerOffer,
                                                     leadOffer,
                                                     fromDate,
                                                     toDate,
                                                     validityDays,
                                                     numberOfLeadsCoupon
                                                 }, index) => {
                                        const isLast = index === data.data.length - 1;
                                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                        return (
                                            <tr key={id}>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {customerOffer}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal">
                                                        {leadOffer}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal">
                                                        {new Date(fromDate).toLocaleString()}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        as="a"
                                                        href="#"
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-medium break-words w-60">
                                                        {new Date(toDate).toLocaleString()}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        as="a"
                                                        href="#"
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-medium break-words w-60">
                                                        {validityDays}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        as="a"
                                                        href="#"
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-medium break-words w-60">
                                                        {numberOfLeadsCoupon}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <IconButton variant="text" size="sm"
                                                                onClick={() => {
                                                                    setDeleteId(id);
                                                                    handleOpenDeleteDialog();
                                                                }}>
                                                        <TrashIcon className="h-5 w-5 text-gray-900"/>
                                                    </IconButton>
                                                </td>
                                            </tr>
                                    );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    )}
            </section>
            <Dialog open={openDeleteDialog} handler={handleOpenDeleteDialog}>
                <DialogHeader>Confirmation</DialogHeader>
                <DialogBody>
                    <Typography
                        color="blue-gray"
                        variant="small"
                        className="font-bold">
                        Are you sure? to delete this Offer?
                    </Typography>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={handleOpenDeleteDialog}
                        className="mr-1"
                    >
                        <span>No</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => deleteRestaurant(deleteId)}>
                        <span>Yes</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    );

}

export default AddOfferPage