import React, {useEffect, useState} from "react";
import { Typography, Input, Button } from "@material-tailwind/react";
import { EyeSlashIcon, EyeIcon } from "@heroicons/react/24/solid";
import { useNavigate } from 'react-router-dom';
import {OrbitSpinner} from 'react-epic-spinners'
import instance from "../util/NetworkInstance";

function LoginPage(props) {
    const navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisibility = () => setPasswordShown((cur) => !cur);

    useEffect(() => {
        setError(null);
    }, [mobileNumber,password]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try{
            const loginData = {
                mobileNumber: mobileNumber,
                password:password
            }
            setIsLoading(true);
            const resp = await instance.post("/login", loginData);
            const data = await resp?.data;
            console.log(data);
            setIsLoading(false);

            if (data && data.accessToken) {
                localStorage.setItem("token", data.accessToken);
                //navigate
                navigate("/dashboard");
            }else{
                setError(data.message);
            }
        }catch (e) {
            setIsLoading(false);
            console.log(e);
            setError(e.message);
        }

    }
    return (
        <section className="grid text-center h-screen items-center p-8">
            {isLoading ? (<OrbitSpinner color="black" className="m-auto" />):
                (<div>
                        <img src="brand-logo.png" alt="brand-image" width="128px" className="m-auto mb-16"/>
                        <Typography className="mb-16 text-gray-600 font-normal text-[16px]">
                            Enter your Mobile Number and Password to sign in
                        </Typography>
                        <form onSubmit={handleSubmit} className="mx-auto max-w-[24rem] text-left">
                            <div className="mb-6">
                                <label htmlFor="mobile">
                                    <Typography
                                        variant="small"
                                        className="mb-2 block font-medium text-gray-900">
                                        Your Mobile Number
                                    </Typography>
                                </label>
                                <Input
                                    variant="outlined"
                                    id="mobile"
                                    color="gray"
                                    size="lg"
                                    type="number"
                                    name="mobile"
                                    placeholder="9988776665"
                                    value={mobileNumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                    className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                    labelProps={{
                                        className: "hidden",
                                    }}/>
                            </div>
                            <div className="mb-6">
                                <label htmlFor="password">
                                    <Typography
                                        variant="small"
                                        className="mb-2 block font-medium text-gray-900">
                                        Password
                                    </Typography>
                                </label>
                                <Input
                                    variant="outlined"
                                    size="lg"
                                    placeholder="********"
                                    labelProps={{
                                        className: "hidden",
                                    }}
                                    className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                    type={passwordShown ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    icon={
                                        <i onClick={togglePasswordVisibility}>
                                            {passwordShown ? (
                                                <EyeIcon className="h-5 w-5"/>
                                            ) : (
                                                <EyeSlashIcon className="h-5 w-5"/>
                                            )}
                                        </i>
                                    }/>
                            </div>
                            {error && <Typography className="mb-16 mt-16 text-red-600 font-normal text-[16px]">
                                {error}
                            </Typography>
                            }

                            <Button color="gray" size="lg" className="mt-6" fullWidth onClick={handleSubmit}>
                                sign in
                            </Button>
                            {/*<div className="mt-4 flex justify-end">*/}
                            {/*    <Typography*/}
                            {/*        as="a"*/}
                            {/*        href="#"*/}
                            {/*        color="blue-gray"*/}
                            {/*        variant="small"*/}
                            {/*        className="font-medium"*/}
                            {/*    >*/}
                            {/*        Forgot password*/}
                            {/*    </Typography>*/}
                            {/*</div>*/}
                            <Typography
                                variant="small"
                                color="gray"
                                className="mt-4 text-center font-normal">
                                Not registered?{" "}
                                <a href="/signup" className="font-medium text-gray-900">
                                    Create account
                                </a>
                            </Typography>
                        </form>
                    </div>
                    )}
        </section>
    );
}

export default LoginPage;