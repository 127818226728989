const isLocalHost = false

const baseUrl = isLocalHost?"localhost":"revuenow.in"
export const BASE_URL = isLocalHost?`http://${baseUrl}:8080/api/v1`:`https://${baseUrl}/api/v1`;
export const BASE_URL_CLIENT = isLocalHost?`http://${baseUrl}:3000`:`https://${baseUrl}`;

export const WHATSAPP_SHARE_MESSAGE_PART_1 = "Hey checkout my food experience at {1} on \n {2}";
export const WHATSAPP_SHARE_MESSAGE_PART_2 =  " And enjoy a special dine in offer for you. 🌟\n" +
"Offer valid until {3}.🙌";
export const DEFAULT_VALIDITY_DAYS = 5;
