import React, {useState, useEffect, useRef} from "react";
import {BASE_URL, BASE_URL_CLIENT} from "../Constants";
import {
    Button,
    Typography,
    Card,
    CardHeader,
    CardBody,
    IconButton,
    Input, Dialog, DialogHeader, DialogFooter, DialogBody,
} from "@material-tailwind/react";

import {QRCodeSVG} from '@akamfoad/qrcode';
import {
    QrCodeIcon,
    TrashIcon,
    EyeIcon,
    MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import useFetchData from "../util/Network";
import {OrbitSpinner} from "react-epic-spinners";
import SimpleNavbar from "../common/SimpleNavbar";
import {useReactToPrint} from 'react-to-print';
import {useNavigate} from "react-router-dom";
import instance from "../util/NetworkInstance";

const TABLE_HEAD = [
    {
        head: "Name",
        customeStyle: "!text-left",
    },
    {
        head: "City",
        customeStyle: "text-right",
    },
    {
        head: "Address",
        customeStyle: "text-right",
    },
    {
        head: "Actions",
        customeStyle: "text-right",
    },
];

function RestaurantsPage() {
    const navigate = useNavigate();
    const {data, error, isLoading, fetchData} = useFetchData();
    const [openQrDialog, setOpenQrDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [qrCodeImage, setQrCodeImage] = useState(null);
    console.log(data);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        fetchData("/restaurants")

    }, []);
    const handleOpenQRDialog = () => setOpenQrDialog(!openQrDialog);

    const handleOpenDeleteDialog = () => setOpenDeleteDialog(!openDeleteDialog);

    async function deleteRestaurant(id) {
        try {
            const header = {"Authorization": "Bearer " + localStorage.getItem("token")};
            const result = await instance.delete( "/restaurants/" + id, {headers: header});
            console.log(result);
            handleOpenDeleteDialog();
            fetchData("/restaurants")
        } catch (e) {
            console.error(e);
        }
    }
    function handleAddRestaurant() {
        navigate("/restaurants/add");
    }

    function handleOpenDetailPage(id){
        navigate("/restaurants/detail/"+id);
    }
    async function showQrCode(qrData) {
        try {
            const qrSVG = new QRCodeSVG(BASE_URL_CLIENT + qrData);
            const dataUrl = qrSVG.toDataUrl();
            setQrCodeImage(dataUrl)
            handleOpenQRDialog()

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div>
            <SimpleNavbar/>
            <section className="m-2 sm:m-10">
                <Card className="h-full w-full bg-gray-100">
                    <CardHeader
                        floated={false}
                        shadow={false}
                        className="rounded-none flex flex-wrap gap-4 justify-between bg-gray-100 items-center">
                        <div>
                            <Typography variant="h6" color="blue-gray">
                                Restaurants
                            </Typography>
                            <Typography
                                variant="small"
                                className="text-gray-600 font-normal mt-0"
                            >
                                All Restaurants
                            </Typography>
                        </div>
                        <div className="flex items-center shrink-0">
                            <div className="">
                                <Button variant="gradient" color="cyan" className="mb-2" onClick={handleAddRestaurant}>
                                    Add Restaurant
                                </Button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody className="overflow-scroll !px-0 py-0">
                        <table className="w-full min-w-max table-auto bg-white">
                            <thead className="bg-gray-100">
                            <tr>
                                {TABLE_HEAD.map(({head, customeStyle}) => (
                                    <th
                                        key={head}
                                        className={`border-b border-gray-300 !p-4 pb-8 ${customeStyle}`}>
                                        <Typography
                                            color="blue-gray"
                                            variant="small"
                                            className="!font-bold text-light-blue-700 text-lg">
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {isLoading ? (<OrbitSpinner color="black" className="m-auto"/>) :
                                data && (data.data.length===0 ? <Typography
                                    color="blue-gray"
                                    variant="small"
                                    className="font-bold m-2">
                                   Restaurants not found!
                                </Typography>:data.data.map(
                                    (
                                        {
                                            id,
                                            name,
                                            city,
                                            address,
                                            qrData
                                        },
                                        index
                                    ) => {
                                        const isLast = index === data.length - 1;
                                        const classes = isLast
                                            ? "!p-4"
                                            : "!p-4 border-b border-gray-300";
                                        return (
                                            <tr key={name}>
                                                <td className={classes}>
                                                    <div className="flex items-center gap-4 text-left">
                                                        {/*<img*/}
                                                        {/*    src={img}*/}
                                                        {/*    alt={name}*/}
                                                        {/*    className="border rounded-md p-1 h-10 w-10"*/}
                                                        {/*/>*/}
                                                        <div>
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="!font-semibold"
                                                            >
                                                                {name}
                                                            </Typography>
                                                            {/*<Typography*/}
                                                            {/*    variant="small"*/}
                                                            {/*    className="!font-normal text-gray-600"*/}
                                                            {/*>*/}
                                                            {/*    {detail}*/}
                                                            {/*</Typography>*/}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        className="!font-normal text-gray-600 text-right"
                                                    >
                                                        {city}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        className="!font-bold text-right"
                                                    >
                                                        {address}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <div className="flex justify-end gap-4">
                                                        <IconButton variant="text" size="sm">
                                                            <QrCodeIcon className="h-5 w-5 text-gray-900"
                                                                        onClick={() => showQrCode(qrData)}/>
                                                        </IconButton>
                                                        <IconButton variant="text" size="sm"
                                                                    onClick={() => {
                                                                        setDeleteId(id);
                                                                        handleOpenDeleteDialog();
                                                                    }}>
                                                            <TrashIcon className="h-5 w-5 text-gray-900"/>
                                                        </IconButton>
                                                        <IconButton variant="text" size="sm"
                                                                    onClick={()=>handleOpenDetailPage(id)}>
                                                            <EyeIcon className="h-5 w-5 text-gray-900"/>
                                                        </IconButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    }
                                ))}
                            </tbody>
                        </table>
                    </CardBody>
                </Card>
            </section>
            <Dialog open={openQrDialog} handler={handleOpenQRDialog}>
                <DialogHeader>Restaurant QR</DialogHeader>
                <DialogBody>
                    {qrCodeImage && <img
                        src={qrCodeImage}
                        alt="QR DATA"
                        className="border rounded-md p-1 h-72 w-72"
                        ref={componentRef}
                    />}
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={handlePrint}
                        className="mr-1"
                    >
                        <span>Print</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={handleOpenQRDialog}>
                        <span>OK</span>
                    </Button>
                </DialogFooter>
            </Dialog>
            <Dialog open={openDeleteDialog} handler={handleOpenDeleteDialog}>
                <DialogHeader>Confirmation</DialogHeader>
                <DialogBody>
                    <Typography
                        color="blue-gray"
                        variant="small"
                        className="font-bold">
                        Are you sure? to delete the restaurant?
                    </Typography>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={handleOpenDeleteDialog}
                        className="mr-1"
                    >
                        <span>No</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => deleteRestaurant(deleteId)}>
                        <span>Yes</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    );
}

export default RestaurantsPage;