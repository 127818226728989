import React from "react";
import {Link, useNavigate} from 'react-router-dom';
import {
    Navbar,
    Collapse,
    Typography,
    Button,
    IconButton,
} from "@material-tailwind/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";

function NavItem({label, link}) {
    return (
        <Link to={link}>
            <Typography as="li" color="blue-gray" className="p-1 font-medium">
                {label}
            </Typography>
        </Link>
    );
}

function NavList() {
    return (
        <ul className="mb-4 mt-2 flex flex-col gap-3 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-8">
            <NavItem label="Restaurants" link="/restaurants"/>
            <NavItem label="Reviews" link="/reviews"/>
            <NavItem label="Leads" link="/leads"/>
        </ul>
    );
}

export function SimpleNavbar() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen((cur) => !cur);
    const navigate = useNavigate();

    function handleLogout() {
        localStorage.clear();
        navigate("/login");
    }

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpen(false)
        );
    }, []);

    return (
        <Navbar color="teal" fullWidth
                className="sticky top-0 z-10 h-max max-w-full rounded-none px-4 py-2 lg:px-8 lg:py-4">
            <div className="container mx-auto flex items-center justify-between text-blue-gray-900">
                <Typography
                    as="a"
                    href="/dashboard"
                    color="blue-gray"
                    className="mr-4 cursor-pointer text-lg font-bold"
                >
                    Restaurants Review
                </Typography>
                <div className="hidden lg:block">
                    <NavList/>
                </div>
                <Button color="gray" className="hidden lg:inline-block" onClick={handleLogout}>
                    Logout
                </Button>
                <IconButton
                    size="sm"
                    variant="text"
                    color="blue-gray"
                    onClick={handleOpen}
                    className="ml-auto inline-block text-blue-gray-900 lg:hidden"
                >
                    {open ? (
                        <XMarkIcon className="h-6 w-6" strokeWidth={2}/>
                    ) : (
                        <Bars3Icon className="h-6 w-6" strokeWidth={2}/>
                    )}
                </IconButton>
            </div>
            <Collapse open={open}>
                <div className="mt-2 rounded-xl bg-white py-2">
                    <NavList/>
                    <Button className="mb-2" fullWidth onClick={handleLogout}>
                        Logout
                    </Button>
                </div>
            </Collapse>
        </Navbar>
    );
}

export default SimpleNavbar;