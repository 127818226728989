import './App.css';
import LoginPage from './ui/login/LoginPage';
import NotFoundPage from './ui/common/NotFoundPage';
import HomePage from './ui/common/HomePage';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Dashboard from "./ui/dashboard/Dashboard";
import ProtectedRoute from "./ui/util/ProtectedRoute";
import RestaurantsPage from "./ui/restaurant/RestaurantsPage";
import AddReviewPage from "./ui/review/AddReviewPage";
import RedirectPage from "./ui/redirect/RedirectPage";
import AddRestaurantPage from "./ui/restaurant/AddRestaurantPage";
import LeadPageOne from './ui/lead/LeadPageOne';
import LeadPageTwo from './ui/lead/LeadPageTwo';
import RestaurantDetailPage from "./ui/restaurant/RestaurantDetailPage";
import SpeechToTextRecord from "./ui/test/SpeechToText";
import AllReviewsPage from './ui/review/AllReviewsPage';
import LeadsPage from './ui/lead/LeadsPage';
import CameraPage from "./ui/camera/CameraPage";
import AddOfferPage from "./ui/offer/AddOfferPage";
import TermsAndConditionPage from "./ui/common/TermsAndConditionPage";
import VerifyCouponPage from "./ui/restaurant/VerifyCouponPage";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/" element={<ProtectedRoute/>}>
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route path="/restaurants" element={<RestaurantsPage/>}/>
                    <Route path="/restaurants/add" element={<AddRestaurantPage/>}/>
                    <Route path="/restaurants/detail/:id" element={<RestaurantDetailPage/>}/>
                    <Route path="/restaurants/offer/add/:id" element={<AddOfferPage/>}/>
                    <Route path='/reviews' element={<AllReviewsPage/>}/>
                    <Route path='/leads' element={<LeadsPage/>}/>
                </Route>
                <Route path="/restaurants/coupon/:id" element={<VerifyCouponPage/>}/>
                <Route path="/review/:id" element={<AddReviewPage/>}/>
                <Route path="/url/:id" element={<RedirectPage/>}/>
                <Route path="/*" element={<NotFoundPage/>}/>
                <Route path='/share/:id' element={<LeadPageOne/>}/>
                <Route path='/share/offer/:id' element={<LeadPageTwo/>}/>
                <Route path='/term-and-conditions' element={<TermsAndConditionPage/>}/>
                {/*<Route path='/test' element={<SpeechToTextRecord/>}/>*/}

                <Route path='/camera' element={<CameraPage/>}/>
            </Routes>
        </Router>
    );
}

export default App;
