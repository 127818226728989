import React, {useState} from 'react'
import {useEffect} from 'react';
import {Card, Select, Typography, Option} from "@material-tailwind/react";
import useFetchData from '../util/Network';
import SimpleNavbar from "../common/SimpleNavbar";
import {OrbitSpinner} from "react-epic-spinners";
import instance from "../util/NetworkInstance";

const TABLE_HEAD = ["Name", "Mobile No.", "Restaurant", "Shared by"];
const defaultFilter = {id: "123", name: "All" };
const LeadsPage = () => {
    const {data, error, isLoading, fetchData} = useFetchData();
    const [filterOptions, setFilterOptions] = useState([defaultFilter]);
    const [filterBy, setFilterBy] = useState(defaultFilter);

    useEffect(() => {
        if (filterBy.name === "All") {
            fetchData("/lead");
        } else {
            fetchData("/lead/restaurant/" + filterBy.id);
        }

    }, [filterBy]);

    useEffect(() => {
        getFilterOptions();
    }, []);

    const getFilterOptions = async () => {
        try {
            const header = {"Authorization": "Bearer " + localStorage.getItem("token")};
            const resp = await instance.get("/restaurants", {headers: header});
            const result = await resp?.data;
            console.log(result);
            if (result) {
                result.data.forEach(({name, id}) => {
                    const found = filterOptions.find((item) => item.id === id);
                    if (found === undefined) {
                        filterOptions.push({'name': name, 'id': id})
                    }
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div>
            {isLoading ? (<OrbitSpinner color="black" className="m-auto"/>) : (
                <div>
                    {error && <Typography className="mb-16 text-red-600 font-normal text-[16px] p-2">
                        {error.message}
                    </Typography>}
                    {data && <div>
                        <SimpleNavbar/>
                        <section className='m-6'>
                            <div className="flex flex-wrap items-center justify-between mb-4">
                                <div className="float-left">
                                    <Typography variant="h2" className='font-bold text-xl'>Leads</Typography>
                                </div>
                                <div>
                                    <Select variant="outlined" label="Restaurant Filter" value={JSON.stringify(filterBy)}
                                            className="float-right"
                                            onChange={(value) => setFilterBy(JSON.parse(value))}>
                                        {
                                            filterOptions.map(({id, name}) => (
                                                <Option key={id} value={JSON.stringify({'id': id, 'name': name})}>{name}</Option>))
                                        }
                                    </Select>
                                </div>
                            </div>

                            <Card className="h-full w-full overflow-auto  shadow-md">
                                <table className="w-full min-w-max table-auto text-left ">
                                    <thead>
                                    <tr>
                                        {TABLE_HEAD.map((head) => (
                                            <th key={head}
                                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-bold text-light-blue-700 text-lg leading-none opacity-70">
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.data.map(({
                                                        name,
                                                        mobileNumber,
                                                        restaurantName,
                                                        review
                                                    }, index) => {
                                        const isLast = index === data.data.length - 1;
                                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                        return (
                                            <tr key={index}>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal">
                                                        {name}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal">
                                                        {mobileNumber}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal">
                                                        {restaurantName}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        as="a"
                                                        href="#"
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-medium break-words w-60">
                                                        {review?review.name:"N/A"}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </Card>
                        </section>
                    </div>}
                </div>)
            }
        </div>
    )

}

export default LeadsPage