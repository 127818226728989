import React, {useEffect, useState} from 'react'
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition';
import {Textarea, Typography, Input, Button, IconButton, Rating} from "@material-tailwind/react";
import {MicrophoneIcon, CameraIcon, PlayCircleIcon, PauseCircleIcon} from "@heroicons/react/24/solid";
import {AudioRecorder, useAudioRecorder} from 'react-audio-voice-recorder';
import {Link, useParams} from "react-router-dom";
import useFetchData from "../util/Network";
import {OrbitSpinner} from "react-epic-spinners";
import instance from "../util/NetworkInstance";
import {
    BASE_URL,
    BASE_URL_CLIENT,
    DEFAULT_VALIDITY_DAYS,
    WHATSAPP_SHARE_MESSAGE_PART_1,
    WHATSAPP_SHARE_MESSAGE_PART_2
} from "../Constants";

function AddReviewPage(props) {
    const {id} = useParams()
    const [foodRating, setFoodRating] = useState(0);
    const [serviceRating, setServiceRating] = useState(0);
    const [experienceRating, setExperienceRating] = useState(0);
    const [reviewText, setReviewText] = useState("");
    const [name, setName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [imageUrl, setImageUrl] = useState(null);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [shareMessage, setShareMessage] = useState("");
    const [offerText, setOfferText] = useState(null);
    const ratingTitles = ["Food", "Service", "Experience"];
    const {data, error, isLoading, fetchData} = useFetchData();
    useEffect(() => {
        fetchData("/restaurants/" + id)

    }, [id]);
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    // const {
    //     startRecording,
    //     stopRecording,
    //     togglePauseResume,
    //     recorderControls,
    //     recordingBlob,
    //     isRecording,
    //     isPaused,
    //     recordingTime,
    //     mediaRecorder
    // } = useAudioRecorder();

    function startListening() {
        // startRecording();
        SpeechRecognition.startListening();
    }

    function stopListening() {
        // stopRecording()
        // SpeechRecognition.stopListening();
    }

    useEffect(() => { //validation
        console.log("validation>> ")
        if (foodRating > 0 && serviceRating > 0 && experienceRating > 0 && reviewText.length > 1 && name.length > 1 && mobileNumber.length === 10) {
            setButtonEnabled(true);
        } else {
            setButtonEnabled(false);
        }
    }, [foodRating, serviceRating, experienceRating, name, mobileNumber]);

    useEffect(() => {
        console.log("listening>> " + listening)
        if (!listening) {
            stopListening()
        }
    }, [listening]);

    useEffect(() => {
        console.log("transcript>> " + transcript)
        if (transcript) {
            setReviewText(transcript)
        }
    }, [transcript]);

    useEffect(() => {
        if (data && data.data.offer){
            if (data.data.offer.customerOffer && data.data.offer.leadOffer){
                const text ="Get "+data.data.offer.customerOffer+" now by sharing review with friends. Your friends will get a discount coupon too.";
                setOfferText(text);
            } else if (data.data.offer.customerOffer && !data.data.offer.leadOffer){
                const text ="Get "+data.data.offer.customerOffer+" now by sharing review with friends.";
                setOfferText(text);
            } else if (!data.data.offer.customerOffer && data.data.offer.leadOffer){
                const text ="Please post a review and share it with your friends and gift them a discount coupon.";
                setOfferText(text);
            }
        }
    }, [data]);

    async function handleSubmit() {
        try {
            var expiry = 0;
            const reviewData = {
                foodRating: foodRating,
                serviceRating: serviceRating,
                experienceRating: experienceRating,
                reviewText: reviewText,
                name: name,
                mobileNumber: mobileNumber,
                restaurantId: data.data.id,
                restaurantName: data.data.name,
                restaurantRating: data.data.overallRatings,
                reviews: data.data.reviews,
            }
            if (data.data.offer) {
                const expiryTimestamp = getExpiryTimestamp(data.data.offer.validityDays ? data.data.offer.validityDays : DEFAULT_VALIDITY_DAYS);
                console.log("expiryTimestamp",expiryTimestamp);
                reviewData["offerId"] = data.data.offer.id;
                reviewData["offerExpireDate"] = expiryTimestamp;
                expiry = expiryTimestamp
            }
            if (imageUrl) {
                reviewData["imageUrl"] = imageUrl
            }
            setIsSubmitting(true);
            const resp = await instance.post("/review", reviewData);
            const result = await resp?.data;
            console.log(result);
            setIsSubmitting(false);
            if (result) {
                createWhatsappShareLink(result.data, expiry)
                setSubmitted(true)
            } else {

            }
        } catch (e) {
            setIsSubmitting(false);
            console.log(e);
            // setError(e.message);
        }
    }

    function getExpiryTimestamp(days) {
        const todayInMillis = Date.now();
        const time= (parseInt(days) * 24 * 60 * 60 * 1000) + todayInMillis;
        const date = new Date(time);
        date.setHours(23, 59, 59);
        return date.getTime();
    }

    async function createWhatsappShareLink(whatsappUrl,offerExpiryTimestamp) {
        const shareMessage1 = WHATSAPP_SHARE_MESSAGE_PART_1.replace("{1}", data.data.name)
            .replace("{2}", BASE_URL_CLIENT + whatsappUrl);
        if (data.data.offer){
            const validity = new Date(offerExpiryTimestamp).toLocaleDateString("en-US",
                {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
            const shareMessage2 = WHATSAPP_SHARE_MESSAGE_PART_2.replace("{3}", validity);

            if (data.data.offer.customerOffer && data.data.offer.leadOffer){
                setShareMessage(shareMessage1 + shareMessage2);
            } else if (data.data.offer.customerOffer && !data.data.offer.leadOffer){
                setShareMessage(shareMessage1);
            } else if (!data.data.offer.customerOffer && data.data.offer.leadOffer){
                setShareMessage(shareMessage1 + shareMessage2);
            }
        }else {
            setShareMessage(shareMessage1);
        }
    }

    function handleRating(rating, title) {
        if (title === ratingTitles[0]) { //food
            setFoodRating(rating)
        } else if (title === ratingTitles[1]) { //Service
            setServiceRating(rating)
        } else if (title === ratingTitles[2]) { //Experience
            setExperienceRating(rating)
        }
        console.log(foodRating);
        console.log(experienceRating);
        console.log(serviceRating);
    }

    const handleFileChange = async (event) => {
        event.preventDefault();
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append('file', image);
        try {
            const response = await instance.post(BASE_URL + '/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                console.log('upload response:', response);
                setImageUrl(response.data.data.url);
            }
        } catch (error) {
            console.error('Upload error:', error);
        }
    };

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }
    return (
        <section className="grid bg-gray-100 h-full max-w-screen-sm">
            {(isLoading || isSubmitting) ? (<OrbitSpinner color="black" className="m-auto"/>) :
                ((!submitted && data) && <div className=''>
                    <div className='flex m-2 p-2 overflow-y-auto rounded-lg bg-white shadow-lg'>
                        <div><img src={data.data.imageUrl ? data.data.imageUrl : "/brand-logo.png"} alt="logo"
                                  className='w-24 rounded'/></div>
                        <div className='ml-2'>
                            <h1 className='font-bold text-xl ml-2 mr-2'>{data.data.name}</h1>
                            <div className="flex ml-2 mr-2 items-center">
                                <Rating value={parseInt(data.data.overallRatings)} unratedColor="gray" ratedColor="blue"
                                        readonly/>
                                {<Typography color="gray" className="ml-2 font-bold text-[14px]">
                                    {data.data.reviews > 1 ? data.data.reviews + " guests" : data.data.reviews + " guest"}
                                </Typography>}
                            </div>

                        </div>
                    </div>
                    {
                        offerText && (<div className="m-4">
                            <p className='text-green-500'>{offerText}</p>
                            <div className="text-end"><a className="text-[12px]" href="/term-and-conditions">
                                <u>Terms & Conditions apply</u></a></div>
                        </div>)
                    }
                    <div className='m-2 p-4 h-max rounded-lg bg-white shadow-lg'>
                        <h6 className='font-bold mb-2 '>Please rate your dine-in experience</h6>
                        <div>
                        {
                                ratingTitles.map((title) =>
                                    <div key={title} className='flex justify-between mb-3'><p
                                        className='text-md'>{title}</p>
                                        <Rating onChange={(value) => handleRating(value, title)}
                                                unratedColor="gray" ratedColor="yellow"/>
                                    </div>)
                            }
                        </div>
                        <div
                            className="flex w-full flex-row gap-2 rounded-lg shadow-lg border border-black bg-gray-900/5 p-2 mt-8">
                            <Textarea
                                rows={4}
                                resize={false}
                                placeholder={browserSupportsSpeechRecognition ?
                                    "Click on mic to record your review or just type your dine in experience and your favorite dish" :
                                    "Type your dine in experience and your favorite dish"}
                                className="min-h-full !border-0 focus:border-transparent"
                                containerProps={{
                                    className: "grid h-full",
                                }}
                                onChange={(e) => setReviewText(e.target.value)}
                                value={reviewText}
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                            />
                            {
                                browserSupportsSpeechRecognition && <div>
                                    <IconButton onClick={startListening} variant="text"
                                                className="rounded-full">
                                        <i>
                                            <MicrophoneIcon className={listening ? "w-8 h-8 animate-pulse" : "w-8 h-8"}
                                                            color={listening ? "red" : "black"}/>
                                        </i>
                                    </IconButton>
                                </div>
                            }
                        </div>
                        <div className="items-center mt-6">
                            <div className='flex'>
                                <i><CameraIcon className='w-8 h-8'/></i>
                                <p className='text font-bold text-center ml-2 mt-1'>Share your experience with a
                                    picture</p>
                            </div>
                            <input className='mt-2' type="file" accept="image/*" onChange={handleFileChange} capture/>
                        </div>
                        <div className="mt-6">
                            <p className='text font-bold'> Your Details</p>
                            <div className='mt-4'>
                                <Input
                                    variant="outlined"
                                    id="name"
                                    color="gray"
                                    size="lg"
                                    type="text"
                                    label='Name'
                                    maxLength="50"
                                    onChange={(e) => setName(e.target.value)}/>
                            </div>
                            <div className='mt-4'>
                                <Input
                                    variant="outlined"
                                    id="number"
                                    color="gray"
                                    size="lg"
                                    type="tel"
                                    maxLength="10"
                                    pattern="[1-9]{1}[0-9]{9}"
                                    label='Mobile number'
                                    onChange={(e) => setMobileNumber(e.target.value)}/>
                            </div>
                        </div>
                        <Button color="gray" size="lg" className="mt-6" fullWidth onClick={handleSubmit}
                                disabled={!buttonEnabled}>Submit
                            Review</Button>

                    </div>
                </div>)}
            {submitted && data && <div className='m-2 p-4 h-max rounded-lg bg-white shadow-lg'>
                <h6 className='font-bold mb-2'>Your review summary</h6>
                <div className="flex m-4 items-center justify-evenly">
                    <div>
                        <img src={imageUrl ? imageUrl : (data.data.imageUrl ? data.data.imageUrl : "/brand-logo.png")}
                             alt="logo"
                             className='w-56 rounded'/>
                    </div>
                    <div className='ml-2 mr-2'>
                        <Typography className="text-gray-500 font-normal text-[16px] text-start">
                            {reviewText}
                        </Typography>
                    </div>
                </div>
                <div className='p-3'>
                    <div className='flex justify-between'>
                        <Typography>Food Rating</Typography>
                        <Rating
                            value={foodRating}
                            unratedColor="gray"
                            ratedColor="yellow"
                            readonly
                        />
                    </div>
                    <div className='flex justify-between'>
                        <Typography>Experience Rating</Typography>
                        <Rating
                            value={experienceRating}
                            unratedColor="gray"
                            ratedColor="yellow"
                            readonly
                        />
                    </div>
                    <div className='flex justify-between'>
                        <Typography className=''>Service Rating</Typography>
                        <Rating
                            value={serviceRating}
                            unratedColor="gray"
                            ratedColor="yellow"
                            readonly
                        />
                    </div>

                </div>
            </div>}
            {
                (submitted && data) && (

                    <a href={"whatsapp://send?text=" + shareMessage}
                       data-action="share/whatsapp/share">
                        <div className="m-3"><Button variant="gradient" fullWidth
                                                     className="flex gap-2 normal-case items-center">
                            <img src="/images/whatsapp_icon.svg" width="34px" height="34px" alt="Icon"/>
                            Click on Whatsapp to share review with three
                            of your friends</Button></div>
                    </a>)
            }
            {error && <Typography className="mb-16 mt-16 text-red-500 font-normal text-[16px]">
                {error.message}
            </Typography>}
        </section>
    );
}

export default AddReviewPage;