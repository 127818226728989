import React, { useState, useRef,useCallback } from 'react';
import Webcam from 'react-webcam';

const CameraPage = () => {
    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);

    const capture = useCallback(() => {
        // const imageSrc = webcamRef.current.getScreenshot();
        // setImgSrc(imageSrc);
        //
        // // Send captured image data back to the source page
        // // (Replace with your actual data transfer mechanism)
        // window.opener.postMessage(imageSrc, '*');
    }, [webcamRef]);

    return (
        <div style={{ height: "100vh", width: "100%" }}>
            <Webcam
                audio={false} // Disable audio if not needed
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ facingMode: { exact: "environment" } }}
            />
            <button onClick={capture}>Capture Photo</button>
            {imgSrc && (
                <img src={imgSrc} alt="Captured image" />
            )}
        </div>
    );
};
export default CameraPage;