import {useParams, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {OrbitSpinner} from "react-epic-spinners";
import {Typography, Rating, Button} from "@material-tailwind/react";
import useFetchData from "../util/Network";
import {MapPinIcon} from "@heroicons/react/24/solid";

function LeadPageTwo(props) {
    const {id} = useParams()
    const navigate = useNavigate();
    const {data, error, isLoading, fetchData} = useFetchData();
    useEffect(() => {
        fetchData("/lead/" + id)
    }, [id]);

    function isOfferValid(timestamp) {
        const today = new Date();
        const expiredDate = new Date(timestamp);
        return today <= expiredDate;
    }

    function getDate(timestamp) {
        const date = new Date(timestamp)
        return date.toLocaleDateString("en-US", {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
    }

    return (
        <div className="p-2 h-full items-center max-w-screen-sm bg-gray-200">
            {
                isLoading ? <OrbitSpinner color="black" className="m-auto"/> : (
                    data && <>
                        <div className="">
                            <div className='flex m-2 p-2 overflow-y-auto rounded-lg bg-white shadow-lg'>
                                <div><img
                                    src={data.data.restaurant.imageUrl ? data.data.restaurant.imageUrl : "/brand-logo.png"}
                                    alt="logo" className='w-24 rounded'/></div>
                                <div className='ml-4 mr-2'>
                                    <h1 className='font-bold text-xl '>{data.data.restaurant.name}</h1>
                                    <div className="flex items-center">
                                        <Rating value={parseInt(data.data.restaurant.overallRatings)}
                                                unratedColor="gray"
                                                ratedColor="blue" readonly/>
                                        <Typography color="gray" className="ml-2 font-bold text-[14px]">
                                            {data.data.restaurant.reviews > 1 ? data.data.restaurant.reviews + " guests" : data.data.restaurant.reviews + " guest"}
                                        </Typography>
                                    </div>

                                </div>
                            </div>
                            {
                                (data.data.restaurant.latitude && data.data.restaurant.longitude) ?
                                    (<a href={"https://maps.google.com/?q=" + data.data.restaurant.latitude + "," + data.data.restaurant.longitude} className="underline">
                                            <div className="flex m-2 mt-4">
                                                <i className=""><MapPinIcon className="h-6 w-6 mr-2"/></i>
                                                <Typography color="blue-gray">{data.data.restaurant.address}</Typography>
                                            </div>
                                        </a>) : (
                                        <div className="flex m-2 mt-4">
                                            <i className=""><MapPinIcon className="h-6 w-6 mr-2"/></i>
                                            <Typography color="blue-gray">{data.data.restaurant.address}</Typography>
                                        </div>
                                    )}
                        </div>
                        <div
                            className='m-2 mt-4 p-4 overflow-y-auto rounded-lg bg-white shadow-lg'>
                            <h6 className='font-bold mb-2 '>{data.data.review.name}'s review</h6>
                            <div className="flex mt-4 items-center justify-between">
                                {data.data.review.imageUrl && <div>
                                    <img
                                        src={data.data.review.imageUrl ? data.data.review.imageUrl : "/brand-logo.png"}
                                        alt="logo"
                                        className='w-56 rounded'/>
                                </div>}
                                <div className='ml-2 mr-2'>
                                    <Typography className="text-gray-500 font-normal text-[16px] text-start">
                                        {data.data.review.reviewText}
                                    </Typography>
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className='flex justify-between'>
                                    <Typography className=''>Food Rating</Typography>
                                    <Rating
                                        value={data.data.review.foodRating}
                                        unratedColor="gray"
                                        ratedColor="yellow"
                                        readonly
                                    />
                                </div>
                                <div className='flex justify-between'>
                                    <Typography className=''>Experience Rating</Typography>
                                    <Rating
                                        value={data.data.review.experienceRating}
                                        unratedColor="gray"
                                        ratedColor="yellow"
                                        readonly
                                    />
                                </div>
                                <div className='flex justify-between'>
                                    <Typography className=''>Service Rating</Typography>
                                    <Rating
                                        value={data.data.review.serviceRating}
                                        unratedColor="gray"
                                        ratedColor="yellow"
                                        readonly
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            (data.data.offer && data.data.offer.leadOffer) && <div className="w-full">
                                {isOfferValid(data.data.review.offerExpireDate) ? (
                                    <div className="m-2">
                                        <p className="mt-4 mb-2 text-yellow-900 text-center text-[16px]">{data.data.offer.coupon?("Congrats! here's a special offer for you")
                                            :("")} </p>
                                        <div
                                            className='mt-4 p-4 overflow-y-auto rounded-lg border-2 border-blue-200 shadow-lg text-black text-[16px] text-center bg-white'>
                                            {data.data.offer.coupon?(
                                                <p>{data.data.offer.leadOffer} on dine-in 🍽️<br/>
                                                    Offer can be applied only once and is valid
                                                    until {getDate(data.data.review.offerExpireDate)}.<br/>
                                                    <p
                                                        className="pl-4 pr-4 pt-2 pb-2 bg-gradient-to-r from-cyan-500 to-blue-500 rounded text-[18px] text-white font-bold">
                                                        {data.data.offer.coupon.code}</p>
                                                </p>) : (
                                                <p>
                                                    Really sorry it was a limited time dine-in offer for the first three guests.
                                                    We would love to serve you by ordering online on the below links.
                                                </p>)
                                            }
                                        </div>
                                    </div>) : (
                                    <p className="m-4">Sorry, you are a bit late in claiming your dine-in offer. Offer expired
                                        on {getDate(data.data.review.offerExpireDate)}.😔</p>
                                )}
                            </div>
                        }
                        <div className="text-end m-2"><a className="text-[12px]" href="/term-and-conditions"><u>Terms
                            & Conditions apply</u></a></div>
                        {(data.data.restaurant.zomatoLink || data.data.restaurant.swiggyLink) && <div className='text-center mt-6'>
                            <Typography>Please click on below links for ordering online🥡</Typography>
                            <div className='flex justify-evenly mt-4 mb-6'>
                                {data.data.restaurant.zomatoLink && <a href={data.data.restaurant.zomatoLink}>
                                    <Button variant="outlined" className="flex items-center">
                                        <img src="/images/zomato.webp" className="w-6 h-6 rounded mr-2"/>
                                        Zomato
                                    </Button>
                                </a>}
                                {data.data.restaurant.swiggyLink && <a href={data.data.restaurant.swiggyLink}>
                                    <Button variant="outlined" className="flex items-center">
                                        <img src="/images/swiggy.webp" className="w-6 h-6 rounded mr-2"/>
                                        Swiggy</Button>
                                </a>}
                            </div>
                        </div>
                        }

                    </>
                )}
        </div>
    )
}


export default LeadPageTwo;