import React, {useEffect} from "react";
import useFetchData from "../util/Network";
import {redirect, useNavigate, useParams} from "react-router-dom";
import {OrbitSpinner} from "react-epic-spinners";
import {Typography} from "@material-tailwind/react";

function RedirectPage(props) {
    const {id} = useParams()
    const navigate = useNavigate();
    const {data, error, isLoading, fetchData} = useFetchData();
    useEffect(() => {
        if (id) {
            fetchData("/url/" + id, "GET", null, false);
        }
    }, [id]);
    useEffect(() => {
        if (data) {
            navigate("/"+data.redirectUrl,{replace:true});
        }
    }, [data]);

    return (
        <section className="grid text-center items-center bg-gray-200 h-screen">
            {isLoading ? (<OrbitSpinner color="black" className="m-auto"/>) :
                (data && <div className=''>
                    <Typography color="black" className="font-bold text-[16px]">
                        {data.message}
                    </Typography>
                </div>)}
            {
                error && <div className=''>
                    <Typography color="black" className="font-bold text-[16px]">
                        {error.message}
                    </Typography>
                </div>
            }
        </section>
    );
}

export default RedirectPage;