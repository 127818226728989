import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {OrbitSpinner} from "react-epic-spinners";
import {Button, Progress, Rating, Typography} from "@material-tailwind/react";
import useFetchData from "../util/Network";
import SimpleNavbar from "../common/SimpleNavbar";
import {QRCodeSVG} from "@akamfoad/qrcode";
import {BASE_URL_CLIENT} from "../Constants";

const RestaurantDetailPage = () => {
    const navigate = useNavigate();
    const {data, error, isLoading, fetchData} = useFetchData();
    const {id} = useParams();
    const [foodGroupRating, setFoodGroupRating] = useState([]);
    const [serviceGroupRating, setServiceGroupRating] = useState([]);
    const [experienceGroupRating, setExperienceGroupRating] = useState([]);
    const [avgFoodRating, setAvgFoodRating] = useState(0);
    const [avgServiceRating, setAvgServiceRating] = useState(0);
    const [avgExperienceRating, setAvgExperienceRating] = useState(0);
    const [qrCodeImage, setQrCodeImage] = useState(null);

    useEffect(() => {
        fetchData("/restaurants/details/" + id);
    }, []);

    function handleOffersClick() {
        navigate("/restaurants/offer/add/" + id);
    }
    function handleCouponClick() {
        navigate("/restaurants/coupon/" + id);
    }

    useEffect(() => {
        if (data) {
            const length = data.data.allReviews.length;
            const foodRatings = data.data.allReviews.map((item) => item.foodRating);
            const experienceRatings = data.data.allReviews.map((item) => item.experienceRating);
            const serviceRatings = data.data.allReviews.map((item) => item.serviceRating);

            setFoodGroupRating(group(foodRatings));
            const fr = foodRatings.reduce((a, b) => a + b, 0);
            setAvgFoodRating(parseInt(fr / foodRatings.length));
            console.log("avgFoodRating",avgFoodRating);

            setServiceGroupRating(group(serviceRatings));
            const sr = serviceRatings.reduce((a, b) => a + b, 0);
            console.log("MK>>", serviceRatings.length);
            setAvgServiceRating(parseInt(sr / serviceRatings.length));
            console.log("avgServiceRating",avgServiceRating);

            setExperienceGroupRating(group(experienceRatings));
            const er = experienceRatings.reduce((a, b) => a + b, 0);
            setAvgExperienceRating(parseInt(er / experienceRatings.length));
            console.log("avgExperienceRating",avgExperienceRating);
            const qrSVG = new QRCodeSVG(BASE_URL_CLIENT + data.data.qrData);
            const dataUrl = qrSVG.toDataUrl();
            setQrCodeImage(dataUrl);
        }
    }, [data]);

    function group(arr) {
        return [5, 4, 3, 2, 1].map((star) => {
            const a = arr.filter((i) => i === star);
            return {
                star: star,
                value: parseInt((a.length / arr.length) * 100)
            };
        });
    }
    return (
        <div className="">
            <SimpleNavbar/>
            <section className="m-2 sm:m-10">
                <div>
                    {isLoading ? (
                        <OrbitSpinner color="black" className="m-auto"/>
                    ) : (
                        <div>
                            {error && (
                                <Typography className="mb-16 text-red-600 font-normal text-[16px] p-2">
                                    {error.message}
                                </Typography>
                            )}
                            {data && (
                                <div className="p-2 bg-gray-100 rounded">
                                    <div
                                        className="bg-indigo-500 text-white rounded-lg p-2 m-2 flex flex-wrap items-center justify-between">
                                        <div>
                                            <img
                                                src={data.data.imageUrl || "/brand-logo.png"}
                                                className="mr-8 rounded sm:w-[256px] w-full"
                                            />
                                        </div>
                                        <div>
                                            <Typography className="sm:mt-1 mt-4 font-bold text-[18px]">{data.data.name}</Typography>
                                            <div className="flex flex-wrap mt-2">
                                            <Rating
                                                    value={parseInt(data.data.overallRatings)}
                                                    unratedColor="gray"
                                                    ratedColor="yellow"
                                                    readonly/>
                                                {
                                                    <Typography
                                                        color="white"
                                                        className="ml-2 font-bold text-[16px]"
                                                    >
                                                        {data.data.reviews > 1
                                                            ? data.data.reviews + " guests"
                                                            : data.data.reviews + " guest"}
                                                    </Typography>
                                                }
                                            </div>
                                            <Button className="mt-4" color="cyan" onClick={handleOffersClick}>Offers</Button>
                                            <Button className="mt-4 ml-2" color="cyan" onClick={handleCouponClick}>Verify Coupon</Button>
                                        </div>
                                        <div className="m-auto sm:mt-1 mt-4">
                                            {qrCodeImage && <img
                                                src={qrCodeImage}
                                                alt="QR DATA"
                                                className="border rounded-md p-1 h-56 w-56 m-2"
                                            />}
                                        </div>
                                    </div>
                                    <div className="">
                                        <h1 className="text-[18px] mt-6 font-bold p-3 text-deep-purple-600">
                                            Ratings and Reviews
                                        </h1>
                                        <div
                                            className="bg-white shadow-lg rounded-lg p-3 flex justify-between flex-wrap ">
                                            <div>
                                                <Typography className="font-bold mb-3">Food Rating</Typography>
                                                <div className="w-full">

                                                    <RatingBar rating={avgFoodRating}/>
                                                    {foodGroupRating.map((rating) => <div
                                                        className="mb-2 flex items-center justify-between gap-4 w-96 ">
                                                        <Typography color="blue-gray"
                                                                    className="w-16 text-blue-600 ">
                                                            {rating.star} Star
                                                        </Typography>
                                                        <Progress value={rating.value} color="amber"/>
                                                        <Typography color="blue-gray" className="w-16  ">
                                                            {rating.value}%
                                                        </Typography>
                                                    </div>)}
                                                </div>
                                            </div>
                                            <div>
                                                <Typography className="font-bold mb-3">Service Rating</Typography>
                                                <div className="w-full">
                                                    <RatingBar rating={avgServiceRating}/>
                                                    {serviceGroupRating.map((rating) => <div
                                                        className="mb-2 flex items-center justify-between gap-4 w-96 ">

                                                        <Typography color="blue-gray" className="w-16 text-blue-600 ">
                                                            {rating.star} Star
                                                        </Typography>
                                                        <Progress value={rating.value} color="amber"/>
                                                        <Typography color="blue-gray" className="w-16  ">
                                                            {rating.value}%
                                                        </Typography>
                                                    </div>)}
                                                </div>
                                            </div>
                                            <div>
                                                <Typography className="font-bold mb-3 ">Experience Rating</Typography>
                                                <div className="w-full">
                                                    <RatingBar rating={avgExperienceRating}/>
                                                    {experienceGroupRating.map((rating1) => <div
                                                        className="mb-2 flex items-center justify-between gap-4 w-96 ">

                                                        <Typography color="blue-gray" className="w-16 text-blue-600 ">
                                                            {rating1.star} Star
                                                        </Typography>
                                                        <Progress value={rating1.value} color="amber"/>
                                                        <Typography color="blue-gray" className="w-16  ">
                                                            {rating1.value}%
                                                        </Typography>
                                                    </div>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h1 className="text-[18px] mt-4 font-bold p-3 text-deep-purple-600">
                                            Reviews
                                        </h1>
                                        <div className="bg-white shadow-lg rounded-lg p-3 ">
                                            <ul>
                                                <li className="text-[16px] font-bold text-blue-700 m-3">
                                                    Typed reviews :{" "}
                                                    <span className="text-black font-normal ">

                          </span>{" "}
                                                </li>
                                                <li className="text-[16px] font-bold text-blue-700 m-3">
                                                    Audio reviews :{" "}
                                                    <span className="text-black font-normal ">

                          </span>
                                                </li>
                                                <li className="text-[16px] font-bold text-blue-700 m-3">
                                                    Photos shared :{" "}
                                                    <span className="text-black font-normal ">

                          </span>
                                                </li>
                                                <li className="text-[16px] font-bold text-blue-700 m-3">
                                                    Total outreach :{" "}
                                                    <span className="text-black font-normal ">

                          </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        <h1 className="text-[18px] mt-4 font-bold p-3 text-deep-purple-600">
                                            Outreach
                                        </h1>
                                        <div className="bg-white shadow-lg rounded-lg p-3 ">
                                            <ul>
                                                <li className="text-[16px] font-bold text-blue-700 m-3">
                                                    Total visitation :{" "}
                                                    <span className="text-black font-normal ">

                          </span>{" "}
                                                </li>
                                                <li className="text-[16px] font-bold text-blue-700 m-3">
                                                    People who visited the webpage but did not share phone
                                                    :{" "}
                                                    <span className="text-black font-normal ">

                          </span>
                                                </li>
                                                <li className="text-[16px] font-bold text-blue-700 m-3">
                                                    Leads who gave their name and phone :{" "}
                                                    <span className="text-black font-normal ">

                          </span>
                                                </li>
                                                <li className="text-[16px] font-bold text-blue-700 m-3">
                                                    Leads who have ordered with discount code :{" "}
                                                    <span className="text-black font-normal ">

                          </span>
                                                </li>
                                                <li className="text-[16px] font-bold text-blue-700 m-3">
                                                    Average order value :{" "}
                                                    <span className="text-black font-normal ">

                          </span>
                                                </li>
                                                <li className="text-[16px] font-bold text-blue-700 m-3">
                                                    Number of leads reminded :{" "}
                                                    <span className="text-black font-normal ">

                          </span>
                                                </li>
                                                <li className="text-[16px] font-bold text-blue-700 m-3">
                                                    Number of lost leads :{" "}
                                                    <span className="text-black font-normal ">

                          </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
};

function RatingBar({rating}) {
    console.log("Rating Bar", rating);
    return <div className="mb-2 flex justify-center w-max p-2 bg-gray-300 rounded-lg">
        <Rating
            value={rating}
            unratedColor="gray"
            ratedColor="amber"
            readonly
        />
        <Typography color="blue-gray" className="ml-2 ">
            {rating} out of 5
        </Typography>
    </div>
}

export default RestaurantDetailPage;
