import useFetchData from "../util/Network";
import {OrbitSpinner} from "react-epic-spinners";
import React, {useEffect} from "react";
import {Card, CardBody, CardHeader, Typography} from "@material-tailwind/react";
import SimpleNavbar from "../common/SimpleNavbar";
import {Link, useNavigate} from "react-router-dom";

function Dashboard(props) {
    const navigate = useNavigate();
    const {data, error, isLoading, fetchData} = useFetchData();
    console.log(data);
    useEffect(() => {
        fetchData("/dashboard")
    }, []);
    useEffect(() => {
        if (error && error.response && error.response.status === 403) {
            navigate("/login");
        }
    }, [error]);
    return (
        <div>
            <SimpleNavbar/>
            
                {isLoading ? (<OrbitSpinner color="black" className="m-auto"/>) :
                    (<div>
                        {error && <Typography className="mb-16 text-red-600 font-normal text-[16px] p-2">
                            {error.message}
                        </Typography>}
                        {data && <div className="flex mt-12 justify-center flex-wrap ">
                        <Link to="/restaurants"> <Card className="w-80 h-40 bg-gray-300 m-8 p-3 rounded-xl shadow-md text-center ">
                              <div className=" flex justify-center"><img src="./images/restaurantIcon.svg" className="w-12"/></div>
                              <h1 className="ml-4 mt-4 text-black font-bold text-xl"> Total Restaurents : {data.data.restaurants} </h1>
                              <div className="flex justify-center">
                              <h1 className="ml-4 mt-4 text-black text-lg">More Info : </h1>
                              <img src="./images/next.png" className="w-6 h-6 mt-4 ml-4"/>
                              </div>
                              
                        </Card>
                        </Link>
                        <Link to="/reviews"> <Card className="w-80 h-40 bg-gray-300 m-8 p-3 rounded-xl shadow-md text-center ">
                        <div className=" flex justify-center"><img src="./images/restaurantIcon.svg" className="w-12"/></div>
                          <h1 className="ml-4 mt-4 text-black font-bold text-xl">Total reviews : {data.data.reviews}</h1>
                          <div className="flex justify-center">
                              <h1 className="ml-4 mt-4 text-black text-lg">More Info : </h1>
                              <img src="./images/next.png" className="w-6 h-6 mt-4 ml-4"/>
                              </div> 
                        </Card></Link>
                        <Link to="/leads"><Card className="w-80 h-40 bg-gray-300 m-8 p-3 rounded-xl shadow-md text-center ">
                        <div className=" flex justify-center"><img src="./images/restaurantIcon.svg" className="w-12"/></div>
                           <h1 className="ml-4 mt-4 text-black font-bold text-xl">Total Leads : {data.data.leads}</h1>
                           <div className="flex justify-center">
                              <h1 className="ml-4 mt-4 text-black text-lg">More Info : </h1>
                              <img src="./images/next.png" className="w-6 h-6 mt-4 ml-4"/>
                            </div>  
                        </Card>
                        </Link>
                        </div>}
                    </div>)}
            
        </div>
    )
}


export default Dashboard;