import {useParams, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {OrbitSpinner} from "react-epic-spinners";
import {Typography, Rating, Input, Button} from "@material-tailwind/react";
import useFetchData from "../util/Network";
import instance from "../util/NetworkInstance";

function LeadPageOne(props) {
    const {id} = useParams();
    const navigate = useNavigate();
    const {data, error, isLoading, fetchData} = useFetchData();
    const [name, setName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [buttonEnabled, setButtonEnabled] = useState(false);
    useEffect(() => {
        fetchData("/review/" + id);
    }, [id]);

    useEffect(() => { //validation
        console.log("validation>> ");
        if (name.length > 1 && mobileNumber.length === 10) {
            setButtonEnabled(true);
        }else{
            setButtonEnabled(false);
        }
    }, [name, mobileNumber]);

    async function handleSubmit() {
        try {
            const leadData = {
                name: name,
                mobileNumber: mobileNumber,
                restaurantId: data.data.restaurantId,
                reviewId: id,
                restaurantName: data.data.restaurant.name,
            }
            if (data.data.offerId){
                leadData["offerId"] = data.data.offerId;
            }
            const resp = await instance.post("/lead", leadData);
            const result = await resp?.data;
            console.log(result);
            if (result) {
                navigate("/share/offer/" + result.data.id, { replace: true })
            }
        } catch (e) {
            console.log(e);
            // setError(e.message);
        }
    }

    return (
        <div className="h-screen items-center bg-gray-200 max-w-screen-sm">
            {
                isLoading ? <OrbitSpinner color="black" className="m-auto"/> : (
                    data && <>
                        <div className='flex m-2 p-2 overflow-y-auto rounded-lg bg-white shadow-lg'>
                            <div><img
                                src={data.data.restaurant.imageUrl ? data.data.restaurant.imageUrl : "/brand-logo.png"}
                                alt="logo" className='w-24 rounded'/></div>
                            <div className='ml-2'>
                                <h1 className='font-bold text-xl ml-2 mr-2'>{data.data.restaurant.name}</h1>
                                <div className="flex ml-2 mr-2 items-center">
                                    <Rating value={parseInt(data.data.restaurant.overallRatings)} unratedColor="gray"
                                            ratedColor="blue" readonly/>
                                    <Typography color="gray" className="ml-2 font-bold text-[14px]">
                                        {data.data.restaurant.reviews > 1 ? data.data.restaurant.reviews + " guests" : data.data.restaurant.reviews + " guest"}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div
                            className='flex m-2 mt-4 p-4 overflow-y-auto rounded-lg border-2 border-blue-200 shadow-lg text-xl text-black text-[16px]'>
                            Please enter your name and phone number to know {data.data.name}'s experience at {data.data.restaurant.name} {(data.data.offer && data.data.offer.leadOffer)?"and unlock a special dine-in offer for yourself":""}
                        </div>
                        <div className='m-2 mt-4 p-4 overflow-y-auto rounded-lg bg-white shadow-lg text-md'>

                            <div className="mb-6">
                                <Input
                                    variant="outlined"
                                    color="gray"
                                    size="lg"
                                    type="text"
                                    required={true}
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                />
                            </div>
                            <div className="mb-6">
                                <Input
                                    variant="outlined"
                                    color="gray"
                                    size="lg"
                                    type="tel"
                                    maxLength="10"
                                    pattern="[1-9]{1}[0-9]{9}"
                                    required={true}
                                    label="Mobile Number"
                                    value={mobileNumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                    className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                                />
                            </div>
                            <Button color="gray" size="lg" className="mt-6" fullWidth onClick={handleSubmit}
                                    disabled={!buttonEnabled}>Submit</Button>
                        </div>
                    </>

                )
            }
        </div>
    )
}

export default LeadPageOne;