import {useState} from "react";
import axios from "axios";
import {BASE_URL} from "../Constants";
const useFetchData = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Added state for loading
    const fetchData = async (url, method = 'GET', body = null, useAuth = true) => {
        console.log("URL>>> " + url);
        console.log(body);
        setIsLoading(true); // Set loading state to true before request
        try {
            const headers = useAuth ? {"Authorization": "Bearer " + localStorage.getItem("token")} : {};
            if (body) {
                headers['Content-Type'] = 'application/json'
            }
            const response = await axios({
                method: method,
                url: `${BASE_URL}${url}`,
                data: body,
                headers: headers,
            });
            console.log(response.data);
            setData(response.data);
        } catch (error) {
            console.error('API call error:', error);
            setError(error);
        } finally {
            setIsLoading(false); // Set loading state to false after request (regardless of success or error)
        }
    };

    return {data, error, isLoading, fetchData};
};
axios.interceptors.request.use(
    (config) => {
        console.log('Request:', config); // Log the request configuration
        return config;
    },
    (error) => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

export default useFetchData;